import { menuItems } from "@/components/menu";

const setMenu = ({ commit }, user) => {
  commit("SET_MENU", user);
};

const replaceLeadsSubItems = (grades) => {
  const updatedMenuItems = menuItems.map((menuItem) => {
    if (menuItem.id === 4) {
      return {
        ...menuItem,
        subItems: grades.map((subItem) => ({
          id: "4" + subItem.id,
          label: subItem.name, // Use 'name' for label
          link: `/leads/${subItem.name.toLowerCase().replace(/ /g, "-")}`, // Generate link
          parentId: 4, // Set parentId for all subItems
        })),
      };
    }
    return menuItem;
  });

  // Find the "Leads" menu item by its label
  const leadsMenuItem = updatedMenuItems.find(
    (item) => item.label === "Leads" && item.subItems
  );

  // Ensure subItems exist before sorting
  if (leadsMenuItem && leadsMenuItem.subItems) {
    leadsMenuItem.subItems.sort((a, b) => +a.id - +b.id);
  }

  return updatedMenuItems;
};

const fetchMenu = async ({ commit }) => {
  try {
    const response = await fetch(`${process.env.VUE_APP_URL}/grade`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status === 401) {
      // Hapus token dari localStorage
      localStorage.removeItem("token");

      // Redirect ke halaman login
      this.$router.push({ name: "login" });
    }

    if (!response.ok) {
      throw new Error("Failed to fetch menu");
    }

    const dataMenu = await response.json();
    const saveMenu = replaceLeadsSubItems(dataMenu.data.data);

    commit("SET_MENU", saveMenu);
  } catch (error) {
    console.error("Error fetching menu from state management:", error.message);
  }
};

export { setMenu, fetchMenu };
