const avatar = (state) => (state.user ? state.user.avatar : "");
const userName = (state) => (state.user ? state.user.name : "Guest");
const email = (state) => state.user?.email || "-";
const phoneNumber = (state) => state.user?.phone_number || "-";
const plan = (state) => state.user?.plan_id || "";
const company = (state) => state.user?.company_id || "";

const setUser = ({ commit }, user) => {
  commit("SET_USER", user);
};

const fetchUser = async ({ commit }) => {
  try {
    const response = await fetch(`${process.env.VUE_APP_URL}/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status === 401) {
      // Hapus token dari localStorage
      localStorage.removeItem("token");

      // Redirect ke halaman login
      this.$router.push({ name: "login" });
    }

    if (!response.ok) {
      throw new Error("Failed to fetch user");
    }

    const { data } = await response.json();
    commit("SET_USER", data);
  } catch (error) {
    console.error("Error fetching user from state management:", error.message);
  }
};

const checkUser = async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return;
  }

  const response = await fetch(`${process.env.VUE_APP_URL}/user`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return await response.json();
};

export {
  avatar,
  userName,
  email,
  phoneNumber,
  setUser,
  fetchUser,
  checkUser,
  plan,
  company,
};
