// import { checkUser } from "@/state/actions/user";

export default [
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    meta: { title: "Not Found", authRequired: false },
    component: () => import("../views/pages/utility/404"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/pages/account/login"),
    meta: {
      title: "Login",
      async beforeResolve(routeTo, routeFrom, next) {
        // const user = await checkUser();

        // Cek jika user ada
        // if (user?.status === "success") {
        if (localStorage.getItem("token")) {
          // Jika user udah login, redirect ke halaman home
          next({ name: "home" });
        } else {
          // Jika token tidak ada, lanjutkan ke halaman login
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot-password",
    component: () => import("../views/pages/account/forgot-password"),
    meta: {
      title: "Forgot Password",
      async beforeResolve(routeTo, routeFrom, next) {
        // const user = await checkUser();

        // Cek jika user ada
        // if (user?.status === "success") {
        if (localStorage.getItem("token")) {
          // Jika user udah login, redirect ke halaman home
          next({
            name: "home",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("../views/pages/account/reset-password"),
    meta: {
      title: "Reset Password",
      async beforeResolve(routeTo, routeFrom, next) {
        // const user = await checkUser();

        // Cek jika user ada
        // if (user?.status === "success") {
        if (localStorage.getItem("token")) {
          // Jika user udah login, redirect ke halaman home
          next({
            name: "home",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/otp",
    name: "otp",
    component: () => import("../views/pages/account/otp"),
    meta: {
      title: "OTP",
      async beforeResolve(routeTo, routeFrom, next) {
        // const user = await checkUser();

        // Cek jika user ada
        // if (user?.status === "success") {
        if (localStorage.getItem("token")) {
          // Jika user udah login, redirect ke halaman home
          next({
            name: "home",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "home",
    meta: { title: "Dashboard", authRequired: true },
    component: () => import("../views/pages/dashboard/index"),
  },
  {
    path: "/profile",
    children: [
      {
        path: "",
        name: "profile",
        meta: { title: "Profile", authRequired: true },
        component: () => import("../views/pages/account/profile"),
      },
      {
        path: "change-password",
        name: "change-password",
        meta: { title: "Change Password", authRequired: true },
        component: () => import("../views/pages/account/change-password"),
      },
    ],
  },
  {
    path: "/billing",
    name: "billing",
    meta: { title: "Billing", authRequired: true },
    component: () => import("../views/pages/account/billing"),
  },
  {
    path: "/leads",
    children: [
      {
        path: ":grade/create/:id",
        name: "lead-create",
        meta: { title: "Create", authRequired: true },
        component: () => import("../views/pages/app/leads/create"),
      },
      {
        path: ":grade",
        name: "lead-grade",
        meta: { title: "Type Lead", authRequired: true },
        component: () => import("../views/pages/app/leads/grade"),
      },
      {
        path: ":grade/:id",
        name: "lead-detail",
        meta: { title: "Detail Lead", authRequired: true },
        component: () => import("../views/pages/app/leads/detail"),
      },
      {
        path: ":grade/edit/:id",
        name: "lead-edit",
        meta: { title: "Detail Lead", authRequired: true },
        component: () => import("../views/pages/app/leads/edit"),
      },
      {
        path: ":grade/follow-up/:id",
        name: "lead-follow-up",
        meta: { title: "Detail Lead Follow Up", authRequired: true },
        component: () => import("../views/pages/app/leads/follow-up"),
      },
      {
        path: ":grade/detail/edit/:id",
        name: "lead-detail-edit",
        meta: { title: "Detail Lead", authRequired: true },
        component: () => import("../views/pages/app/leads/detail-edit"),
      },
    ],
  },
  {
    path: "/invoices",
    children: [
      {
        path: "unpaid",
        name: "invoice-unpaid",
        meta: { title: "Invoice Unpaid", authRequired: true },
        component: () => import("../views/pages/app/invoices/unpaid"),
      },
      {
        path: "paid",
        name: "invoice-paid",
        meta: { title: "Invoice Paid", authRequired: true },
        component: () => import("../views/pages/app/invoices/paid"),
      },
      {
        path: "overdue",
        name: "invoice-overdue",
        meta: { title: "Invoice Overdue", authRequired: true },
        component: () => import("../views/pages/app/invoices/overdue"),
      },
      {
        path: ":type/detail/:id",
        name: "invoice-detail",
        meta: { title: "Invoice Detail", authRequired: true },
        component: () => import("../views/pages/app/invoices/detail"),
      },
    ],
  },
  {
    path: "/orders",
    children: [
      {
        path: "",
        name: "order",
        meta: { title: "Order", authRequired: true },
        component: () => import("../views/pages/order/list/order"),
      },
      {
        path: "create",
        name: "order-create",
        meta: { title: "Create Order", authRequired: true },
        component: () => import("../views/pages/order/list/create"),
      },
      {
        path: "create/:id",
        name: "order-create-leads",
        meta: { title: "Create Order from Lead", authRequired: true },
        component: () => import("../views/pages/order/list/create"),
      },
      {
        path: "edit/:id",
        name: "order-edit",
        meta: { title: "Edit Order", authRequired: true },
        component: () => import("../views/pages/order/list/edit"),
      },
      {
        path: ":id",
        name: "order-detail",
        meta: { title: "Detail Order", authRequired: true },
        component: () => import("../views/pages/order/list/detail"),
      },
    ],
  },
  {
    path: "/events",
    children: [
      {
        path: "",
        name: "events",
        meta: { title: "Events", authRequired: true },
        component: () => import("../views/pages/order/events/event"),
      }
    ],
  },

  {
    path: "/add-sales",
    name: "add-sales",
    meta: { title: "Add Sales", authRequired: true },
    component: () => import("../views/pages/report/add-sales/index"),
  },

  {
    path: "/sales",
    name: "sales",
    meta: { title: "Sales", authRequired: true },
    component: () => import("../views/pages/report/sales"),
  },
  {
    path: "/sales/detail-report/:id",
    name: "detail-sales",
    meta: { title: "Detail Sales", authRequired: true },
    component: () => import("../views/pages/report/sales/detail-report"),
  },
  {
    path: "/sales/detail-performance/:id",
    name: "detail-sales-performance",
    meta: { title: "Detail Sales Performance", authRequired: true },
    component: () => import("../views/pages/report/sales/detail-performance"),
  },
  {
    path: "/sales/detail-leads-report/:id",
    name: "detail-leads-report",
    meta: { title: "Detail Leads Report", authRequired: true },
    component: () => import("../views/pages/report/sales/detail-leads-report"),
  },
  {
    path: "/sales/detail-top-venue/:id",
    name: "detail-top-venue",
    meta: { title: "Detail Top Venue", authRequired: true },
    component: () => import("../views/pages/report/sales/detail-top-venue"),
  },
  {
    path: "/reference",
    children: [
      {
        path: "",
        name: "reference",
        meta: { title: "Reference", authRequired: true },
        component: () => import("../views/pages/report/reference/reference"),
      }
    ],
  },
  {
    path: "/bank-account",
    children: [
      {
        path: "",
        name: "bank-account",
        meta: { title: "Bank Account", authRequired: true },
        component: () => import("../views/pages/setting/bank-account"),
      },
      {
        path: "create",
        name: "create-bank-account",
        meta: { title: "Create Bank", authRequired: true },
        component: () => import("../views/pages/setting/create"),
      },
      {
        path: "edit/:id",
        name: "edit-bank-account",
        meta: { title: "Edit Bank", authRequired: true },
        component: () => import("../views/pages/setting/edit"),
      },
    ],
  },
];
