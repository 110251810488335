const setCompany = ({ commit }, company) => {
  commit("SET_COMPANY", company);
};

const getUrl = (url) => {
  // Gunakan regex untuk menemukan bagian sebelum "api/v1"
  const regex = /^(https?:\/\/[^/]+)(?:\/.*)?$/;
  const hasil = url.match(regex);

  // Jika bagian yang ingin diambil ditemukan, kembalikan
  if (hasil) {
    return hasil[1];
  } else {
    // Jika tidak ditemukan, kembalikan URL asli
    return url;
  }
};

const fetchCompany = async ({ commit }) => {
  try {
    const response = await fetch(`${process.env.VUE_APP_URL}/company`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status === 401) {
      // Hapus token dari localStorage
      localStorage.removeItem("token");

      // Redirect ke halaman login
      this.$router.push({ name: "login" });
    }

    if (!response.ok) {
      throw new Error("Failed to fetch company");
    }

    const company = await response.json();

    // jika ada logo
    if (company.data.logo) {
      const url = getUrl(process.env.VUE_APP_URL);
      company.data.logo = `${url}/storage/${company.data.logo}`;
    }

    commit("SET_COMPANY", company.data);
  } catch (error) {
    console.error(
      "Error fetching company from state management:",
      error.message
    );
  }
};

export { setCompany, fetchCompany };
