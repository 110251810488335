export const menuItems = [
  {
    id: 1,
    label: "menuitems.menu.text",
    isTitle: true,
  },
  {
    id: 2,
    label: "menuitems.dashboard.text",
    icon: "uil-home-alt",
    link: "/",
  },
  {
    id: 3,
    label: "app",
    isTitle: true,
  },
  {
    id: 4,
    label: "Leads",
    icon: "uil-user-circle",
    subItems: [
      {
        id: 41,
        label: "Priority",
        link: "/leads/priority",
        parentId: 4,
      },
      {
        id: 42,
        label: "Grade A",
        link: "/leads/grade-a",
        parentId: 4,
      },
      {
        id: 43,
        label: "Grade B",
        link: "/leads/grade-b",
        parentId: 4,
      },
      {
        id: 44,
        label: "Grade C",
        link: "/leads/grade-c",
        parentId: 4,
      },
    ],
  },
  {
    id: 6,
    label: "Orders",
    isTitle: true,
  },

  {
    id: 7,
    label: "Event This Month",
    icon: "uil-calendar-alt",
    link: "/events",
  },
  {
    id: 8,
    label: "Order List",
    icon: "uil-shopping-cart",
    link: "/orders",
  },

  {
    id: 9,
    label: "Invoices",
    isTitle: true,
  },

  {
    id: 10,
    label: "Waiting Payment",
    icon: "uil-invoice",
    link: "/invoices/unpaid",
  },
  {
    id: 11,
    label: "Overdues",
    icon: "uil-calendar-alt",
    link: "/invoices/overdue",
  },
  {
    id: 12,
    label: "Paid",
    icon: "uil-invoice",
    link: "/invoices/paid",
  },

  {
    id: 13,
    label: "Master Data",
    isTitle: true,
  },
  {
    id: 14,
    label: "Bank Account",
    icon: "uil-credit-card",
    link: "/bank-account",
  },
  {
    id: 15,
    label: "Reference",
    icon: "uil-file-search-alt",
    link: "/reference",
  },

  {
    id: 16,
    label: "Report",
    isTitle: true,
  },
  {
    id: 17,
    label: "Add Sales",
    icon: "uil-graph-bar",
    link: "/add-sales",
  },
  {
    id: 18,
    label: "Sales",
    icon: "uil-graph-bar",
    link: "/sales",
  },
];
