import { createStore } from "vuex";
import modules from "./modules";
import { setMenu, fetchMenu } from "./actions/menu";
import { setCompany, fetchCompany } from "./actions/company";
import {
  avatar,
  userName,
  email,
  phoneNumber,
  setUser,
  fetchUser,
  plan,
  company,
} from "./actions/user";

const store = createStore({
  state: {
    user: null,
    menu: null,
    company: null,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_MENU(state, menu) {
      state.menu = menu;
    },
    SET_COMPANY(state, company) {
      state.company = company;
    },
  },
  actions: {
    setUser,
    fetchUser,
    setMenu,
    fetchMenu,
    setCompany,
    fetchCompany,
  },
  getters: {
    avatar,
    userName,
    email,
    phoneNumber,
    plan,
    company,
  },
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== "production",
});

export default store;
